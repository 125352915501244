import React from "react";

const AmountBox = (props) => {
    const { preDefindAmounts, handleAmountChange, amount } = props;

    return (<div className="amountBox">
        { preDefindAmounts.map((type) => (<div
            className={ type.value === amount ? "amount-box" : "amount-box-selected" }
            key={ type.value }
            onClick={ () => handleAmountChange(type) }
        >
            <div key={ type.value }>
                <span>{ type.label }</span>
            </div>
        </div>)) }
    </div>);
};

export default AmountBox;