import "./App.css";
import AddPaymentMethodScreen from "./CardForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreDonatePage from './pages/PreDonation';
import CardFormPage from './pages/Paymnet';
import Wallet from './pages/wallet';

function App() {
  return (
    <Router>
      <Routes>
          <Route path="/" element={<PreDonatePage />} />
          <Route path="/donate" element={<CardFormPage />} />
          <Route path="/wallet" element={<Wallet />} />
      </Routes>
    </Router>
  );
}

export default App;
