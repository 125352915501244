import React, { useEffect, useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Visa from '../assets/visa1.svg';
import MasterCard from '../assets/mastercard.svg';
import Amex from '../assets/amex.svg';
import CardExmaple from '../assets/cardexample.svg';
import Check from '../assets/Check.svg';
import '../App.css';
import SuccessComponent from '../components/success';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from "react-i18next";
import BackArrow from "../assets/Back.svg";

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const CardForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const navigate = useNavigate();

    // State variables
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [showAddCard, setShowAddCard] = useState(false);

    const [donationError, setDonationError] = useState(null);

    const [accessToken, setAccessToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentMethodsLoading, setPaymentMethodsLoading] = useState(false);

    const [amount, setAmount] = useState(null);
    const [url, setUrl] = useState('invitations');
    const [method, setMethod] = useState('post');
    const [organization, setOrganization] = useState(null);
    const [addedBalance, setAddedBalance] = useState(null);
    const [succeeded, setSucceeded] = useState(false);

    const [currency, setCurrency] = useState(null);
    const [receiver, setReceiver] = useState(null);

    const [isCardNumberValid, setIsCardNumberValid] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [isCardCvcValid, setIsCardCvcValid] = useState(false);
    const [cardIcon, setCardIcon] = useState(null);

    const { t, i18n } = useTranslation();

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                fontSize: "18px",
                color: "#424770",
                backgroundColor: "#FAF2F0",
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#aab7c4", fontSize: "12px", fontWeight: "500", backgroundColor: "#FAF2F0",
                },
            }, invalid: {
                color: "#9e2146",
            },
        },
    };

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const queryCurrency = query.get("currency");
        const queryReceiver = query.get("receiver");
        const queryLanguage = query.get("language") || "en";
        const queryAmount = query.get("amount");
        const queryaddedBalance = query.get("addedBalance");
        const queryUrl = query.get("url");
        const querymethod = query.get("method");
        const queryOrganization = query.get("organization");
        const token = query.get("Authorization");


        setAccessToken(token);
        setCurrency(queryCurrency);
        setReceiver(queryReceiver);
        setAmount(queryAmount);
        setAddedBalance(queryaddedBalance);
        if (setUrl) setUrl(queryUrl);
        if (querymethod) setMethod(querymethod);
        setOrganization(queryOrganization);

        i18n.changeLanguage(queryLanguage);
    }, [location, i18n]);

    useEffect(() => {
        if (!accessToken) return;
        setPaymentMethodsLoading(true);
        axios
            .get(baseUrl + "/payment-methods", {
                headers: {
                    Authorization: `Bearer ${ accessToken }`,
                },
            })
            .then((response) => {
                setPaymentMethods(response.data.paymentMethods);
                setPaymentMethodsLoading(false);
                if (response.data.paymentMethods.length === 0) {
                    setShowAddCard(true);
                } else {
                    setSelectedPaymentMethod(response.data.paymentMethods[0].id);
                }
            })
            .catch((error) => {
                setPaymentMethodsLoading(false);
                console.error("Error fetching payment methods", error);
            });
    }, [accessToken]);

    const handleDonate = async () => {
        setIsLoading(true);
        setDonationError(null);
        let paymentMethodId = selectedPaymentMethod;

        if (showAddCard || !selectedPaymentMethod) {
            const cardNumberElement = elements.getElement(CardNumberElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card", card: cardNumberElement,
            });

            if (error) {
                setDonationError(t("paymentError"));
                setIsLoading(false);
                return;
            }

            paymentMethodId = paymentMethod.id;
        }
        const payload = {
            amount,
            currency,
            receiver,
            paymentMethodId,
            attach: showAddCard || !selectedPaymentMethod, ...(organization && { organization }), ...(addedBalance && { addedBalance }),
        };

        axios[method](baseUrl + url, payload, {
            headers: {
                Authorization: `Bearer ${ accessToken }`,
            },
        })
            .then((response) => {
                setIsLoading(false);
                if (response.data && (response.data.id || response.data.balance)) {
                    setSucceeded(true);
                } else {
                    setDonationError(t("paymentError"));
                }
            })
            .catch((error) => {
                setDonationError(t("paymentError"));
                console.error("Error submitting donation", error);
                setIsLoading(false);
            });
    };

    const handleCardNumberChange = async (event) => {
        setIsCardNumberValid(event.complete && !event.error);
        if (event.brand === "visa") {
            setCardIcon(Visa);
        } else if (event.brand === "mastercard") {
            setCardIcon(MasterCard);
        } else if (event.brand === "amex") {
            setCardIcon(Amex);
        } else {
            setCardIcon(CardExmaple);
        }
    };

    const handleCardExpiryChange = async (event) => {
        setIsCardExpiryValid(event.complete && !event.error);
    };

    const handleCardCvcChange = async (event) => {
        setIsCardCvcValid(event.complete && !event.error);
    };

    const filteredCards = paymentMethods.filter((v, i, a) => a.findIndex((t) => t.last4 === v.last4) === i && a.findIndex((t) => t.id === v.id) === i);
    const handleGoBack = () => {
        if (!showAddCard) {
            navigate(-1);
        } else setShowAddCard(false);
    };

    return (<div style={ succeeded ? mainContainerSuccess : mainContainer }>
        { succeeded ? (<SuccessComponent addedBalance={addedBalance}/>) : (<div>
            <div onClick={ handleGoBack }>
                <img
                    src={ BackArrow }
                    alt="BackArrow"
                    style={ {
                        marginRight: 8, width: "20px", marginBottom: 10,
                    } }
                />
            </div>
            <div className="title">{ t("cardTitle") }</div>
            { ((!selectedPaymentMethod || showAddCard) && !isPaymentMethodsLoading) && (<div>
                <div style={ { marginTop: 16 } }>
                    <span className="cardText">{ t("cardNumber") }</span>
                    <div
                        style={ {
                            padding: "12px",
                            width: "327px",
                            borderRadius: "8px",
                            background: "#FAF2F0",
                            marginTop: "4px",
                            flexDirection: "row",
                            display: "flex",
                        } }
                    >
                        { cardIcon && (<div
                            style={ {
                                height: "25px", width: "25px", marginRight: 8,
                            } }
                        >
                            <img src={ cardIcon } alt="Card Icon"/>
                        </div>) }
                        <div style={ { flex: 1 } }>
                            <CardNumberElement
                                options={ CARD_ELEMENT_OPTIONS }
                                onChange={ handleCardNumberChange }
                            />
                        </div>
                    </div>
                </div>
                <span className="cardText"> { t("cardExpire") }</span>
                <div
                    style={ {
                        padding: "12px", borderRadius: "8px", width: "140px", background: "#FAF2F0", marginTop: "4px",
                    } }
                >
                    <CardExpiryElement
                        options={ CARD_ELEMENT_OPTIONS }
                        onChange={ handleCardExpiryChange }
                    />
                </div>
                <span className="cardText"> { t("cardCVC") }</span>
                <div
                    style={ {
                        padding: "12px", borderRadius: "8px", background: "#FAF2F0", width: "120px", marginTop: "4px",
                    } }
                >
                    <CardCvcElement
                        options={ CARD_ELEMENT_OPTIONS }
                        onChange={ handleCardCvcChange }
                    />
                </div>
            </div>) }
            { isPaymentMethodsLoading && <Skeleton count={ 2 } className="h-10 !rounded-[8px]"/> }
            { !isPaymentMethodsLoading && filteredCards.map((paymentMethod) => (!showAddCard && (<button
                onClick={ () => setSelectedPaymentMethod(paymentMethod.id) }
                className="container"
                key={ paymentMethod.id }
            >
                <div className="flex items-center">
                    { paymentMethod.brand === "visa" ? (
                        <img src={ Visa } alt="Visa" style={ { marginRight: 8, width: "20px" } }/>) : (
                        <img src={ MasterCard } alt="MasterCard"
                             style={ { marginRight: 8, width: "20px" } }/>) }
                    { paymentMethod.last4 }
                </div>
                { selectedPaymentMethod === paymentMethod.id && <img src={ Check } alt="Check"/> }
            </button>))) }

            { !showAddCard && (<div>
                <button
                    className="donate"
                    disabled={ !selectedPaymentMethod }
                    onClick={ handleDonate }
                >
                    { isLoading ? <span className="loader"></span> :
                        <span className="donate">{ addedBalance ? t("button5") : t("button2") }</span> }
                </button>
                { selectedPaymentMethod && <buttonx
                    onClick={ () => setShowAddCard(true) }
                    className="editBtn"
                >
                    { t("button4") }
                </buttonx> }
            </div>) }

            { showAddCard && (<button
                onClick={ handleDonate }
                disabled={ isLoading || !(isCardNumberValid && isCardCvcValid && isCardExpiryValid) }
            >
                { isLoading ? <span className="loader"></span> : <span className="donate">{ t("button2") }</span> }
            </button>) }

            { donationError && <div className="message">{ donationError }</div> }
        </div>) }
    </div>);
};

export default CardForm;

const mainContainer = {
    marginTop: 40, display: "flex", alignItems: "center", paddingLeft: 20,
};

const mainContainerSuccess = {
    display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh",
};
