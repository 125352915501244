import Success from "../assets/success.svg";
import { useTranslation } from "react-i18next";

const SuccessComponent = ({ addedBalance }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="success">
      <img
        src={Success}
        alt="Success"
        style={{
          marginRight: 8,
          width: "40px",
          height: "40px",
          marginBottom: 10,
        }}
      />

      {addedBalance ? (
        <text className="successText">{t("paymentSuccess")}</text>
      ) : (
        <div>
          <text className="successText">{t("paymentSuccess")}</text>
        </div>
      )}
    </div>
  );
};

export default SuccessComponent;
