import React, { useState } from "react";
import useParseUrl from "../hooks/useParseUrl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Wallet = () => {
  const navigate = useNavigate();

  const amounts = [
    {
      value: 100,
      inCurrency: 100,
    },
    {
      value: 500,
      inCurrency: 500,
    },
    {
      value: 1000,
      inCurrency: 1000,
    },
  ];
  const [activeAmount, setActiveAmount] = useState(amounts[0].inCurrency);
  const { t } = useTranslation();

  const { receiver, language, token } = useParseUrl();

  const handleProceed = () => {
    const balance = amounts.filter(
      (item) => item.inCurrency === activeAmount
    )[0]?.value;

    navigate(
      `/donate?Authorization=${token}&method=put&addedBalance=${balance}&url=user/balance&amount=${activeAmount}&currency=DKK&receiver=${receiver}&language=${language}`
    );
  };

  console.log("querycurrency", language);

  return (
    <div className="mt-10 px-5">
      <span className="block font-bold text-xl text-center text-grey ">
        {t("walletTitle")}
      </span>
      <span className="block font-medium text-grey text-center mt-1">
        {t("walletDescription")}
      </span>
      <div className="flex flex-col gap-4 mt-8">
        {amounts.map((type, index) => (
          <div
            onClick={(event) => {
              event.stopPropagation();
              setActiveAmount(type.inCurrency);
            }}
            className="bg-white rounded-[5px] w-full p-4  flex items-center justify-center relative"
            key={type.value}
          >
            <div className="py-8 w-full flex justify-center">
              <span className="text-3xl text-grey ">{type.value}</span>
              <span className="text-3xl text-grey ml-2 text-[#AA0028]">
                DKK
              </span>
            </div>

            <div className="checkboxWrapper !absolute !top-4 right-4">
              <div
                className={
                  type.inCurrency === activeAmount
                    ? "checkbox checked"
                    : "checkbox"
                }
              />
            </div>
          </div>
        ))}
      </div>
      <div
        onClick={handleProceed}
        className="mt-5 bg-[#AA0028] text-white text-md flex items-center
         justify-center rounded-[8px] h-12 w-[250px] mx-auto"
      >
        <span>{t("walletButton")}</span>
      </div>
    </div>
  );
};

export default Wallet;
