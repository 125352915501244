import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

const currencyToSymbolMap = {
    EUR: "€", DKK: "kr",
};

const defaultAmounts = [3, 10, 40, 100, 250];
const danishAmounts = [25, 75, 300, 750, 2000];

const arrayTypes = [{
    value: "world_wildlife_fund", label: "World Wildlife Fund",
}, {
    value: "red_cross", label: "Danish Red Cross",
}, {
    value: "sos_children_villages", label: "Sos Children Villages",
},];

let preDefindAmounts = [];

const useParseUrl = () => {
    const location = useLocation();

    const [amount, setAmount] = useState(null);
    const [organization, setOrganization] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [receiver, setReceiver] = useState(null);
    const [language, setLanguage] = useState("en");
    const [token, setToken] = useState(null);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const queryCurrency = query.get("currency") || "EUR";
        const queryReceiver = query.get("receiver") || "default_receiver";
        const queryLanguage = query.get("language") || "en";
        let queryToken = query.get("Authorization");

        setCurrency(queryCurrency);
        setReceiver(queryReceiver);
        setLanguage(queryLanguage);
        setToken(queryToken);

        // Set predefined amounts based on currency
        if (queryCurrency === "DKK") {
            preDefindAmounts = danishAmounts.map((amount) => ({
                value: amount, label: amount + " " + currencyToSymbolMap[queryCurrency],
            }));
        } else {
            preDefindAmounts = defaultAmounts.map((amount) => ({
                value: amount, label: amount + " " + currencyToSymbolMap[queryCurrency],
            }));
        }

        setAmount(preDefindAmounts[0].value);
        setOrganization(arrayTypes[0].value);
    }, [location]);

    return { amount, organization, currency, receiver, language, token, preDefindAmounts, setAmount, setOrganization };
};

export default useParseUrl;