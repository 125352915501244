import React from 'react';
import { useNavigate } from 'react-router-dom';
import PreDonateComponent from '../components/preDonate';
import useParseUrl from "../hooks/useParseUrl";

const PreDonatePage = () => {
    const navigate = useNavigate();
    const {
        amount, organization, currency, receiver, language, token, preDefindAmounts, setAmount, setOrganization
    } = useParseUrl();

    const handleProceed = () => {
        // On proceed, redirect to the card form page with query params
        navigate(`/donate?Authorization=${ token }&amount=${ amount }&url=invitations&organization=${ organization }&currency=${ currency }&receiver=${ receiver }&language=${ language }`);
    };

    return (<div className="mt-10">
        <PreDonateComponent
            preDefindAmounts={ preDefindAmounts }
            onOrganizationChange={ setOrganization }
            onAmountChange={ setAmount }
            handleProceedToPayment={ handleProceed }
        />
    </div>);
};

export default PreDonatePage;
